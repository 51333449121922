import Web3 from 'web3'
import i18n from '@/utils/lang'

export default new class {
    /**
     * ETH请求实例
     * @returns {Promise<Eth>}
     * @private
     */
    async _eth() {
        const provider = window.ethereum
        if (provider) {
            return new Web3(provider).eth
        } else {
            return Promise.reject(i18n.t('web3.no_install'))
        }
    }

    /**
     * 链接钱包
     * @returns {Promise<Modules.Eth>}
     */
    async link() {
        const eth = await this._eth()
        try {
            await eth.requestAccounts()
            return eth
        } catch (err) {
            const error = err.innerError || err
            switch (error.code) {
                case 4001:
                    return Promise.reject(i18n.t('web3.link_error.reject'))
                case -32002:
                    return Promise.reject(i18n.t('web3.link_error.waiting'))
                default:
                    return Promise.reject(i18n.t('web3.link_error.fail'))
            }
        }
    }

    /**
     * 获取第一个地址
     * @returns {Promise<string|string>}
     */
    async address() {
        const eth = await this._eth()
        const accounts = await eth.getAccounts()
        return accounts[0] || ''
    }

    /**
     * 签名
     * @param address
     * @param str
     * @returns {Promise<*>}
     */
    async sign(address, str) {
        const eth = await this._eth()
        try {
            return await eth.personal.sign(str, address, '')
        } catch (err) {
            const error = err.innerError || err
            switch (error.code) {
                case 4001:
                    return Promise.reject(i18n.t('web3.sign_error.reject'))
                default:
                    return Promise.reject(i18n.t('web3.sign_error.fail'))
            }
        }
    }
}
