 // store/preventReClick.js
 import Vue from 'vue';
 const preventReClick = Vue.directive('preventReClick', {

     inserted: function(el, binding) {
         console.log(binding)
         el.addEventListener('click', () => {
             if (!el.disabled) {
                 el.disabled = true;
                 const { value } = binding; // 假设value是发送请求的函数
                 value().finally(() => {
                     el.disabled = false;
                 });
             }
         });
     }
 });


 export default preventReClick;