import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        itemPromptSettings: {}, // 用于存储每个列表项不再提示的状态
    },
    getters: {
        shouldShowPromptForItem: (state) => (itemId) => {
            return !state.itemPromptSettings[itemId];
        },
    },
    mutations: {
        setItemPromptSetting(state, { itemId, shouldPrompt }) {
            Vue.set(state.itemPromptSettings, itemId, !shouldPrompt); // 如果不再提示，则存为false
        },
    },
    actions: {
        toggleItemPrompt({ commit }, payload) {
            commit('setItemPromptSetting', payload);
        },
    },
    modules: {
        auth,
    }
})