import request from '@/utils/request'

/**
 * 获取随机数
 * @param address
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getNonce = (address) => {
    return request.post('/auth/nonce', {
        address
    })
}

/**
 * 登录
 * @param address
 * @param sign
 * @param is_dao
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const login = (address, sign, is_dao) => {
    return request.post('/auth/login', {
        address,
        sign,
        is_dao
    })
}

/**
 * 刷新TOKEN
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const refreshToken = () => {
    return request.post('/auth/refresh_token')
}