import { Toast } from 'vant'

function isToken() {
    console.log(111)
    let token = localStorage.getItem('token')
    if (!token) {
        Toast('未登录请先登录')
        return false
    } else {
        return true
    }
}

export default {
    isToken,
}