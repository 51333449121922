import Auth from '@/utils/auth'
import Web3Eth from '@/utils/web3'
import { getNonce, login, refreshToken } from '@/api/auth'
import { Toast } from 'vant'
import i18n from '@/utils/lang'
import { utils } from 'web3'

let refreshTokenLock = false
let monitorInterval

const state = () => ({
    address: Auth.getAddress(),
    address: Auth.getIsdao(),
    token: Auth.getToken(),
    isLogin: Boolean(Auth.getToken()),
    isShow: false,
    data: {},
    isClick: true,

})

const mutations = {
    setAddress(state, address) {
        state.address = address
    },
    switchLogin(state, token) {
        state.token = token
        state.isLogin = Boolean(token)
    },
    handleShow(state, val) {
        state.isShow = val
    },
    setData(state, payload) {
        state.data = payload
    },
    setIsClick(state, val) {
        state.isClick = val
    }
}

const actions = {
    async sign() {
        const account = await Web3Eth.address()
        const { nonce, address } = await getNonce(account)
        const sign = await Web3Eth.sign(address, nonce)
        return { sign, nonce, address }
    },
    async checkLogin({ commit, dispatch }, isLogin) {
        let account
        try {
            account = await Web3Eth.address()
            commit('handleShow', true)


        } catch (err) {
            if (isLogin) {
                Toast({
                    message: err,
                    duration: 2000,
                })
                return Promise.reject(err)
            }
            commit('handleShow', false)

            return
        }

        const address = Auth.getAddress()
        const token = Auth.getToken()
        if (
            account &&
            address &&
            token &&
            account === utils.toChecksumAddress(address)
        ) {
            commit('setAddress', address)
            commit('switchLogin', token)
        } else if (isLogin) {
            await dispatch('logout')
            await dispatch('login')
        } else {
            dispatch('logout')
        }
    },
    async login({ commit, dispatch }) {
        const loading = Toast.loading({
            message: 'Loading',
            duration: 0,
            forbidClick: true,
        })
        try {
            await Web3Eth.link()
            let is_dao = 1
            const { sign, address } = await dispatch('sign')
            const { token_type, access_token, expired_at, dao_beginner } = await login(
                address,
                sign,
                is_dao
            )
            const token = `${token_type} ${access_token}`
            commit('setAddress', address)
            commit('switchLogin', token)
            Auth.setToken(address, token, expired_at, dao_beginner)
            loading.clear()
            commit('handleShow', true)
        } catch (err) {
            commit('handleShow', false)
            Toast({
                message: err,
                duration: 2000,
            })
            return Promise.reject(err)
        }

        dispatch('monitor')
    },
    logout({ commit }) {
        commit('setAddress', '')
        commit('switchLogin', '')
        commit('handleShow', false)
        Auth.removeToken()
    },
    async refreshToken({ state, commit }) {
        if (refreshTokenLock) {
            return
        }

        refreshTokenLock = true

        const { token_type, access_token, expired_at, dao_beginner } = await refreshToken()
        const token = `${token_type} ${access_token}`
        commit('switchLogin', token)
        Auth.setToken(state.address, token, expired_at, dao_beginner)

        refreshTokenLock = false
    },
    monitor({ state, dispatch }) {
        if (!state.isLogin) {
            return
        }

        if (monitorInterval) {
            clearInterval(monitorInterval)
        }

        monitorInterval = setInterval(async() => {
            await dispatch('checkLogin')
            if (!state.isLogin) {
                Toast({
                    message: i18n.t('web3.logouted'),
                    duration: 2000,
                })
                clearInterval(monitorInterval)
            }
        }, 200)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}